<template>
  <div class="content-list-square mb-2">
    <b-button :class="item.background" class="square-text mr-3 mb-2" v-for="(item, index) in items" :key="index" @click.prevent="() => $emit('filterEvents', item)">
      {{ item.title }}
    </b-button>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue"
export default {
  components: {
    BButton
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    }
  }
}
</script>
<style lang="css">
@import url("../calendar/css/calendar.css");
</style>