import { mapGetters } from "vuex";
import ActionCRUD from "@/mixins/ActionCRUD";
import PermissionAction from "@/mixins/PermissionAction";
import moment from "moment";
moment.locale("es");

export default {
  inject: ["travelRepository"],
  mixins: [ActionCRUD, PermissionAction],
  data() {
    return {
      records: [],
      events: [],
      copyEvents: [],
      items: [
        {
          title: "Todas",
          background: "square-all",
        },
        {
          title: "Con ventas",
          background: "square-primary",
        },
        {
          title: "Sin ventas",
          background: "square-success",
        },
        {
          title: "Finalizada",
          background: "square-finished",
        },
      ],
      record: null,
      isBusy: false,
      isEdit: false,
      isDisabled: false,
      isTravel: true,
      title: "Crear tour(s) en un rango de fechas",
      id: "travel-modal",
      module: "e087a527-4606-4cf4-a7cc-c3048f100093",
      repository: "travelRepository",
      initialDate: null,
      finalDate: null,
      currentDate: new Date().toLocaleDateString("fr-CA", {
        timeZone: "America/Bogota",
      }),
    };
  },
  computed: {
    ...mapGetters("user", ["getEnterprise"]),
  },
  methods: {
    async handleLoadDataDate() {
      const me = this;
      try {
        me.isBusy = true;
        me.events = [];
        const { data } = await me.travelRepository.getAllTravelByDate(
          me.getEnterprise.uuid,
          me.initialDate,
          me.finalDate
        );
        data.forEach((el) => {
          el.occupied = 0;
          el.available = el.transportation.capacity;
          el.isTourRealized = el.departure_date < me.currentDate ? true : false;
        });
        me.records = data;
        me.handleLoadSaleDate();
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      } finally {
        me.isBusy = false;
      }
    },
    async handleLoadSaleDate() {
      const me = this;
      try {
        const { data } = await me.travelRepository.getAllSaleByDate(
          me.initialDate,
          me.finalDate
        );

        if (data.length > 0) {
          me.records.forEach((el) => {
            data.forEach((y) => {
              if (y.travelUuid == el.uuid) {
                y.chairSale.forEach((x) => {
                  el.occupied += 1;
                });
              }
            });
            el.available = Number(el.available) - el.occupied;
          });
        }
        me.loadSaleWithTravel();
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      }
    },
    loadClass(item) {
      let value = ["square-success"];
      if (item.isTourRealized) {
        value = ["square-finished"];
      } else if (item.occupied > 0) {
        value = ["square-primary"];
      } 

      return value;
    },
    loadStatus(item) {
      let value = "Sin ventas";
      if (item.isTourRealized) {
        value = "Finalizada";
      } else if (item.occupied > 0) {
        value = "Con ventas";
      } 

      return value;
    },
    handleCreating() {
      const me = this;
      me.title = "Crear tour";
      me.isEdit = false;

      me.record = null;
      me.$bvModal.show(me.id);
    },
    handleDetail(item) {
      const me = this;
      me.title = "Detalle Tour";
      me.isDisabled = true;
      if (!item.transportation) item.transportationUuid = null;
      me.record = item;
      me.$bvModal.show(me.id);
    },
    handleEditing(item) {
      const me = this;
      me.title = "Actualizar Tour";
      me.isEdit = true;
      me.isDisabled = false;
      if (!item.transportation) item.transportationUuid = null;
      me.record = item;
      me.$bvModal.show(me.id);
    },
    async handleDeleting(id) {
      await this.del(id);
    },
    loadSaleWithTravel() {
      const me = this;
      me.records.forEach((el) => {
        me.events.push({
          title: `(${el.available}) - ${el.transportation.name} - ${el.transportation.typeTransportation.name}`,
          start: `${el.departure_date} 00:00:00`,
          end: `${el.departure_date} 11:59:59`,
          classNames: me.loadClass(el),
          status: me.loadStatus(el),
          extendedProps: {
            uuid: el.uuid,
          },
        });
      });
      me.copyEvents = me.events;
    },
    findDateStartAndDateEnd(date) {
      const me = this;
      me.initialDate = moment(date).startOf('month').format('YYYY-MM-DD');
      me.finalDate = moment(date).endOf('month').format('YYYY-MM-DD');
      me.handleLoadDataDate();
    },
    filterEvents(item) {
      const me = this;
      let records = [];

      if (item.title == "Todas"){
        records = me.copyEvents;
      } else {
        records = me.copyEvents.filter(el => el.status == item.title);
      }

      me.events = records;
    }
  },
  mounted() {
    const me = this;
    if (me.isCall) me.findDateStartAndDateEnd(new Date()); 
  },
};
