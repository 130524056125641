<template>
  <section class="">
    <b-row>
      <b-col cols="12" :class="{ 'form-disabled': isBusy }">
        <FullCalendar ref="fullCalendar" :options="calendarOptions" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import moment from "moment";
moment.locale("es");
import dayGridPlugin from "@fullcalendar/daygrid";
import esLocale from "@fullcalendar/core/locales/es";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/vue";
import { BCol, BRow } from "bootstrap-vue";

export default {
  components: {
    BCol,
    BRow,
    FullCalendar,
  },
  props: {
    events: {
      type: Array,
      default: () => []
    },
    isBusy: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        locale: esLocale,
        headerToolbar: {
          start: "title",
          center: "",
          end: "today prev,next",
        },
        customButtons: {
          today: {
            text: "Hoy",
            click: () => {
              const me = this;
              let calendarApi = me.$refs.fullCalendar.getApi();
              calendarApi.today();
              me.findDateStartAndDateEnd(calendarApi.getDate().toISOString());
            },
          },
          prev: {
            text: "PREV",
            click: () => {
              const me = this;
              let calendarApi = me.$refs.fullCalendar.getApi();
              calendarApi.prev();
              me.findDateStartAndDateEnd(calendarApi.getDate().toISOString());
            },
          },
          next: {
            // this overrides the next button
            text: "NEXT",
            click: () => {
              const me = this;
              let calendarApi = me.$refs.fullCalendar.getApi();
              calendarApi.next();
              me.findDateStartAndDateEnd(calendarApi.getDate().toISOString());
            },
          },
        },
        events: this.events,
        eventClick: this.handleEventClick,
        dayMaxEventRows: true,
        // views: {
        //   dayGridMonth: {
        //     dayMaxEventRows: 6
        //   }
        // }
      },
    };
  },
  watch: {
    events() {
      const me = this;
      me.calendarOptions.events = me.events;
    }
  },
  methods: {
    handleEventClick(arg) {
      const me = this;
      const { uuid } = arg.event.extendedProps;
      me.$router.push({ name: "ticket", params: { travelUuid: uuid } });
    },
    findDateStartAndDateEnd(date) {
      const me = this;
      const start = moment(date).startOf('month').format('YYYY-MM-DD');
      const end = moment(date).endOf('month').format('YYYY-MM-DD');
      me.$emit("search", start, end);
    }
  },
};
</script>

<style lang="css">
@import url("./css/calendar.css");
</style>
