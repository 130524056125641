<template>
  <b-form @submit.prevent="saveData" ref="form" autocomplete="off">
    <b-row class="mb-3">
      <b-col lg="12" md="12" sm="12" class="mb-3">
        <div class="label_input text-white">* Campos requeridos.</div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3" v-if="isEdit && !record">
        <div role="group">
          <label class="label_input">Fecha de salida*</label>
          <b-form-input
            size="sm"
            type="date"
            v-model="formData.departure_date"
            disabled
          />
        </div>
      </b-col>
      <b-col lg="12" md="12" sm="12" class="mb-3" v-else-if="!isEdit">
        <date-picker
          v-model="formData.rangeDates"
          is-range
          :attributes="attrs"
          color="blue"
          mode="date"
          :model-config="modelConfig"
          :available-dates="{ start: new Date(), end: null }"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <b-row>
              <b-col lg="6" md="6" sm="12">
                <div role="group">
                  <label class="label_input"
                    >Fecha inicial*</label
                  >
                  <b-form-input
                    size="sm"
                    :value="inputValue.start"
                    v-on="inputEvents.start"
                    readonly
                  />
                </div>
              </b-col>
              <b-col lg="6" md="6" sm="12">
                <div role="group">
                  <label class="label_input"
                    >Fecha final*</label
                  >
                  <b-form-input
                    size="sm"
                    :value="inputValue.end"
                    v-on="inputEvents.end"
                    readonly
                  />
                </div>
              </b-col>
            </b-row>
          </template>
        </date-picker>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">Hora de salida*</label>
          <b-form-input
            size="sm"
            type="time"
            v-model="formData.departure_time"
            :disabled="isDisabled"
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">Transporte*</label>
          <v-select
            :options="transportations"
            :reduce="(option) => option.uuid"
            label="name"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.transportationUuid"
            :disabled="isDisabled || isTourRealized"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="price" class="label_input">Precio base*</label>
          <b-form-input
            id="price"
            size="sm"
            placeholder="Precio"
            v-model="formData.price"
            @keyup="formatInput"
            :disabled="isDisabled || isTourRealized"
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="pilot" class="label_input">Piloto o conductor</label>
          <b-form-input
            id="pilot"
            size="sm"
            placeholder="Piloto"
            v-model="formData.pilot"
            :disabled="isDisabled"
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="co_pilot" class="label_input">Copiloto1 o ayudante</label>
          <b-form-input
            id="co_pilot"
            size="sm"
            placeholder="Copiloto1"
            v-model="formData.co_pilot"
            :disabled="isDisabled"
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="co_pilot2" class="label_input">Copiloto2 o ayudante</label>
          <b-form-input
            id="co_pilot2"
            size="sm"
            placeholder="Copiloto2"
            v-model="formData.co_pilot2"
            :disabled="isDisabled"
          />
        </div>
      </b-col>
    </b-row>
    <b-row class="footer-modal-customer">
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button
          type="submit"
          class="btn btn-success w-width"
          :disabled="isBusy || $v.$invalid"
        >
          <b-spinner class="mr-2" v-if="isBusy" small />
          Guardar
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BForm,
  BSpinner,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import ActionCRUD from "@/mixins/ActionCRUD";
import { mapGetters } from "vuex";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import { formatNumber } from "@/utils";

export default {
  inject: ["travelRepository", "transportationRepository"],
  components: {
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BSpinner,
    vSelect,
    DatePicker,
  },
  mixins: [ActionCRUD],
  props: {
    record: {
      type: Object,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isTourRealized: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isBusy: false,
      formData: {
        transportationUuid: null,
        departure_time: '',
        rangeDates: {
          start: "",
          end: "",
        },
        pilot: '',
        co_pilot: '',
        co_pilot2: '',
        price: '',
      },
      repository: "travelRepository",
      transportations: [],
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },
      attrs: [
        {
          highlight: "orange",
          dates: new Date(),
        },
      ],
    };
  },
  computed: {
    ...mapGetters("user", ["getEnterprise"]),
  },
  validations() {
    const me = this;
    const defaultValidation = {
      transportationUuid: {
        required,
      },
      departure_time: {
        required,
      },
      price: {
        required,
      },
    }

    return me.isEdit ? {
      formData: {
        ...defaultValidation
      }
    } : {
      formData: {
        ...defaultValidation,
        rangeDates: {
          required,
        },
      }
    }
  },
  async mounted() {
    const me = this;
    await me.loadTransportations();
    if (me.record) {
      me.formData = { 
        transportationUuid: me.record?.transportationUuid,
        departure_time: me.record?.departure_time,
        pilot: me.record?.pilot,
        co_pilot: me.record?.co_pilot,
        co_pilot2: me.record?.co_pilot2,
        price: me.record?.price,
        uuid: me.record?.uuid,
      }
    }
  },
  methods: {
    async loadTransportations() {
      const me = this;
      try {
        const { data } = await me.transportationRepository.getAll(me.getEnterprise.uuid);
        data.forEach(el => {
          el.name = el.typeTransportation.name + " " + el.name;
        });
        me.transportations = data;
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      }
    },
    formatInput() {
      const me = this;
      me.formData.price = formatNumber(me.formData.price);
    }
  },
};
</script>
